import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import RegisterComponent from "../components/RegisterComponent/RegisterComponent";

export default function Register() {
    return (
        <div>
            <Navbar/>
            <div>
                <RegisterComponent/>
            </div>
<Footer/>
        </div>
    )
}