import Doctors from "../components/Doctors/Doctors";
import Footer from "../components/Footer/Footer";
import Form from "../components/FormClient/FormClient";
import Navbar from "../components/Navbar/Navbar";
import ServicesComponents from "../components/Services/Services";


export default function Home() {
    return (
        <div>
            <Navbar/>
            <div>
   <Form/>
            </div>
            <div>
                <ServicesComponents/>
            </div>
            <div>
                <Doctors/>
            </div>
            <Footer/>
        </div>
    )
}