import CardsComponent from '../components/Cards/Cards';
import Navbar from '../components/Navbar/Navbar';
import Footer from "../components/Footer/Footer";

export default function Cards() {
    return (
        <div>
                <Navbar/>

            <div>
                <CardsComponent/>
            </div>
            <Footer/>

        </div>
    )
}