import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { addDays, startOfWeek, format } from "date-fns";
import { Box } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const daysOfWeek = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];
const availableTimes = [
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
];
const bookedTimes = {
  Lunes: ["11:00 AM", "1:00 PM", "4:00 PM"],
  Martes: ["10:00 AM", "12:00 PM", "3:00 PM"],
  Miércoles: ["11:00 AM", "2:00 PM", "5:00 PM"],
  Jueves: ["10:00 AM", "1:00 PM", "4:00 PM"],
  Viernes: ["11:00 AM", "2:00 PM", "3:00 PM"],
  Sábado: ["10:00 AM", "12:00 PM", "1:00 PM"],
  Domingo: ["11:00 AM", "3:00 PM", "5:00 PM"],
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosIcon
      className={className}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
}

export default function RecipeReviewCard() {
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [weekOffsets, setWeekOffsets] = React.useState([0, 1, 2, 3]);

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  const settings = {
    dots: false,
    infinite: true, // Cambiado a true para navegación infinita
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

  };

  const getWeekDates = (startDate, weekOffset) => {
    const startOfWeekDate = startOfWeek(addDays(startDate, weekOffset * 7), {
      weekStartsOn: 1,
    });
    return daysOfWeek.map((_, index) =>
      format(addDays(startOfWeekDate, index), "dd/MM/yyyy")
    );
  };

  const startDate = new Date();

  return (
    <div className="card-container">

    <Box className="card-box">
      <div>
        <div className="dr-data">
          <Avatar
            src="https://s3.sa-east-1.amazonaws.com/doctoraliar.com/doctor/59f734/59f734e27ec2c85fd3cb342d58892c6b_medium_square.jpg"
            sx={{ bgcolor: red[500], width: 80, height: 80 }}
            aria-label="recipe"
          ></Avatar>
          <div>
            <strong>Dra. Stella Maris Fernandez Garcia</strong>
            <p>
              Dermatólogo (Dermatología clínica, Psoriasis, Cicatrización de
              heridas)
            </p>
            <Typography variant="body2" color="text.secondary">
              Consulta en línea: $18.000
            </Typography>
          </div>
        </div>
      </div>

      <CardContent className="container-reserva">
        <Typography variant="h6" gutterBottom>
          Reserva tu cita
        </Typography>
        <Slider {...settings} className="slider">
          {weekOffsets.map((weekOffset) => (
            <div key={weekOffset}> 
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {getWeekDates(startDate, weekOffset).map((date, index) => (
                  <div
                    key={daysOfWeek[index]}
                    style={{ flex: 1, margin: "0 5px", textAlign: "center" }}
                  >
                    <Typography variant="h6" >{daysOfWeek[index]}</Typography>
                    <Typography variant="subtitle2">{date}</Typography>
                    {availableTimes.map((time) => (
                      <button
                        key={time}
                        onClick={() =>
                          handleTimeClick(
                            `${daysOfWeek[index]} ${date} ${time}`
                          )
                        }
                        style={{
                          margin: "5px 0",
                          backgroundColor: bookedTimes[
                            daysOfWeek[index]
                          ].includes(time)
                            ? "grey"
                            : selectedTime ===
                              `${daysOfWeek[index]} ${date} ${time}`
                            ? "blue"
                            : "white",
                          color: bookedTimes[daysOfWeek[index]].includes(time)
                            ? "white"
                            : "black",
                          cursor: bookedTimes[daysOfWeek[index]].includes(time)
                            ? "not-allowed"
                            : "pointer",
                          pointerEvents: bookedTimes[
                            daysOfWeek[index]
                          ].includes(time)
                            ? "none"
                            : "auto",
                          border:
                            selectedTime ===
                            `${daysOfWeek[index]} ${date} ${time}`
                              ? "2px solid black"
                              : "1px solid grey",
                        }}
                        disabled={bookedTimes[daysOfWeek[index]].includes(time)}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
        {selectedTime && (
          <Typography
            variant="body2"
            color="text.secondary"
            className="info-reservation"
          >
            Hora seleccionada: {selectedTime}
          </Typography>
        )}
      </CardContent>
    </Box>

    <Box className="card-box">
      <div>
        <div className="dr-data">
          <Avatar
            src="https://s3.sa-east-1.amazonaws.com/doctoraliar.com/doctor/59f734/59f734e27ec2c85fd3cb342d58892c6b_medium_square.jpg"
            sx={{ bgcolor: red[500], width: 80, height: 80 }}
            aria-label="recipe"
          ></Avatar>
          <div>
            <strong>Dra. Stella Maris Fernandez Garcia</strong>
            <p>
              Dermatólogo (Dermatología clínica, Psoriasis, Cicatrización de
              heridas)
            </p>
            <Typography variant="body2" color="text.secondary">
              Consulta en línea: $18.000
            </Typography>
          </div>
        </div>
      </div>

      <CardContent className="container-reserva">
        <Typography variant="h6" gutterBottom>
          Reserva tu cita
        </Typography>
        <Slider {...settings} className="slider">
          {weekOffsets.map((weekOffset) => (
            <div key={weekOffset}> 
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {getWeekDates(startDate, weekOffset).map((date, index) => (
                  <div
                    key={daysOfWeek[index]}
                    style={{ flex: 1, margin: "0 5px", textAlign: "center" }}
                  >
                    <Typography variant="h6" >{daysOfWeek[index]}</Typography>
                    <Typography variant="subtitle2">{date}</Typography>
                    {availableTimes.map((time) => (
                      <button
                        key={time}
                        onClick={() =>
                          handleTimeClick(
                            `${daysOfWeek[index]} ${date} ${time}`
                          )
                        }
                        style={{
                          margin: "5px 0",
                          backgroundColor: bookedTimes[
                            daysOfWeek[index]
                          ].includes(time)
                            ? "grey"
                            : selectedTime ===
                              `${daysOfWeek[index]} ${date} ${time}`
                            ? "blue"
                            : "white",
                          color: bookedTimes[daysOfWeek[index]].includes(time)
                            ? "white"
                            : "black",
                          cursor: bookedTimes[daysOfWeek[index]].includes(time)
                            ? "not-allowed"
                            : "pointer",
                          pointerEvents: bookedTimes[
                            daysOfWeek[index]
                          ].includes(time)
                            ? "none"
                            : "auto",
                          border:
                            selectedTime ===
                            `${daysOfWeek[index]} ${date} ${time}`
                              ? "2px solid black"
                              : "1px solid grey",
                        }}
                        disabled={bookedTimes[daysOfWeek[index]].includes(time)}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
        {selectedTime && (
          <Typography
            variant="body2"
            color="text.secondary"
            className="info-reservation"
          >
            Hora seleccionada: {selectedTime}
          </Typography>
        )}
      </CardContent>
    </Box>
    </div>

  );
}
