import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import RegisterDocotorComponent from "../components/RegisterDoctors/RegisterDoctors";

export default function RegisterDoctor() {
    return (
        <div>
            <Navbar/>
            <div>
                <RegisterDocotorComponent/>
            </div>
            <Footer/>
        </div>
    )
}