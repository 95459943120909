import * as React from "react";
import Form from "react-bootstrap/Form";
import { IoSearchOutline } from "react-icons/io5";
import { BsCameraVideoFill } from "react-icons/bs";
import { TbBuildingFortress } from "react-icons/tb";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link } from 'react-router-dom'
export default function FormClient() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <section id="hero">
        <div
          id="heroCarousel"
          data-bs-interval="5000"
          classNameName="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <ol
            className="carousel-indicators"
            id="hero-carousel-indicators"
          ></ol>

          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active background-img">
              <div className="container">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "1em",
                      }}
                    >
                      <TabList onChange={handleChange}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "1em",
                          }}
                      >
                        <Tab
                      
                          label={
                            <div>
                              <TbBuildingFortress /> Turno presencial
                            </div>
                          }
                          value="1"
                        />
                        <Tab
                      
                          label={
                            <div>
                              <BsCameraVideoFill /> En linea
                            </div>
                          }
                          value="2"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div className="flex-input">
                        <Form.Select aria-label="Default select example">
                          <option>Especialidad</option>
                          <option value="2">Ginecólogo</option>
                          <option value="3">Clínico</option>
                          <option value="3">Odontólogo</option>
                          <option value="3">Psicólogo</option>
                          <option value="3">Traumatólogo</option>
                          <option value="3">Dermatólogo</option>
                          <option value="3">Oftamólogo</option>
                          <option value="3">Neurólogo</option>
                        </Form.Select>
                        <Form.Select aria-label="Default select example">
                          <option>Lugar</option>
                          <option value="1">Rosario</option>
                          <option value="2">Lima</option>
                          <option value="3">La paz</option>
                          <option value="3">Machu pichu</option>
                        </Form.Select>
                        <Link to="/doctores" className="search-btn scrollto">

                          <span className="d-none d-md-inline">
                            <IoSearchOutline />
                          </span>{" "}
                          Buscar
                        </Link>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      {" "}
                      <div className="flex-input">
                        <Form.Select aria-label="Default select example">
                          <option>Especialidad</option>
                          <option value="2">Ginecólogo</option>
                          <option value="3">Clínico</option>
                          <option value="3">Odontólogo</option>
                          <option value="3">Psicólogo</option>
                          <option value="3">Traumatólogo</option>
                          <option value="3">Dermatólogo</option>
                          <option value="3">Oftamólogo</option>
                          <option value="3">Neurólogo</option>
                        </Form.Select>

                        <Link to="/doctores" className="search-btn scrollto">
                          <span className="d-none d-md-inline">
                            <IoSearchOutline />
                          </span>{" "}
                          Buscar
                        </Link>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
