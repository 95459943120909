import LoginComponent from "../components/LoginComponent/LoginComponent";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";



export default function Login() {
    return (
        <div>
            <Navbar/>
            <div>
                <LoginComponent/>
            </div>
            <Footer/>
        </div>
    )
}