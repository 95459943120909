import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { RegisterUser } from '../../redux/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function RegisterComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "", 
    phone: ""

  });


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      

      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      const registers = await dispatch(RegisterUser(data));
      if (registers) {
          navigate("/iniciar-sesión");
      }
    setValidated(true);
  } catch (error) {
    alert('Usuario ya existe')

      console.log(error)
  } 
  };


  const handleChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  }
  return (
    <Form
      className="form-register"
      validated={validated}
      onSubmit={handleSubmit}
    >
      <div className="logo-register">
        <div className="logo-container">
          <img src={require("../../assets/img/logo.png")} alt="" />
        </div>
      </div>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
          name="name"
          value={data.name}
          onChange={handleChange}
            placeholder="Nombre"
            type="text"
            required
          />

          <Form.Control.Feedback type="invalid">
            El nombre es requerido.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Apellido</Form.Label>
          <Form.Control
              name="lastName"
              value={data.lastName}
          onChange={handleChange}

            placeholder="Apellido"
            type="text"
            required
          />

          <Form.Control.Feedback type="invalid">
          El apellido es requerido.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control
                 name="email"
                 value={data.email}
             onChange={handleChange}
            type="email"
            placeholder="Correo electrónico"
            required
          />

          <Form.Control.Feedback type="invalid">
          El correo es requerido y/o @.
          </Form.Control.Feedback>
        </Form.Group>

     
      </Row>

      <Row className="mb-3">
      <Form.Group as={Col} md="12" controlId="validationCustom04">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control
             name="password"
             value={data.password}
         onChange={handleChange}
           type="password"
            placeholder="Contraseña" 
            required />

          <Form.Control.Feedback type="invalid">
          La contraseña es requerido.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
      <Form.Group as={Col} md="12" controlId="validationCustom04">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control
                name="phone"
                value={data.phone}
            onChange={handleChange}
           type="number"
            placeholder="Teléfono"
             required />

          <Form.Control.Feedback type="invalid">
          El Teléfono es requerido.

          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
        <Form.Check
          required
          label="Aceptar terminos y condiciones"
          feedback="Aceptar terminos y condiciones es requerido."
          feedbackType="invalid"
        />
      </Form.Group>
      <button type="submit" className="btn-global scrollto">
        <span>Registrarse</span>{" "}
      </button>

      <div className="a-login">
        ¿Ya tienes cuenta?{" "}
        <Link to="/iniciar-sesión" className="scrollto">
          Iniciar sesión
        </Link>
      </div>
    </Form>
  );
}

export default RegisterComponent;