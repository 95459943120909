import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Test from './pages/test';

import Register from './pages/Register';
import RegisterDoctor from './pages/RegisterDoctor';
import Cards from './pages/Cards';


function App() {



  return (
    <Router>
      <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/iniciar-sesión' element={<Login/>}/>
          <Route path='/registrarse' element={<Register/>}/>
          <Route path='/registrarse-doctor' element={<RegisterDoctor/>}/>
          <Route path='/doctores' element={<Cards/>}/>

          <Route path='/test' element={<Test/>}/>



   


      </Routes>
    </Router>
  );
}

export default App;
